<template>
  <v-container fluid tag="section">

    <base-material-card icon="mdi-chart-line">
      <slot slot="title">Sales Summary - All Events</slot>
      <v-row class="mt-10">
        <v-col cols="4"></v-col>
        <v-col cols="2" class="text-right">Orders</v-col>
        <v-col cols="2" class="text-right">Products</v-col>
        <v-col cols="2" class="text-right"><span v-if="$vuetify.breakpoint.smAndUp">Product Revenue</span><span v-else>Prod Rev</span></v-col>
        <v-col cols="2" class="text-right"><span v-if="$vuetify.breakpoint.smAndUp">Total Revenue</span><span v-else>Total</span></v-col>
      </v-row>
      <v-row>
        <v-col cols="4">Total</v-col>
        <v-col cols="2" class="text-right">{{allSales.orders}}</v-col>
        <v-col cols="2" class="text-right">{{allSales.products}}</v-col>
        <v-col cols="2" class="text-right">{{utils.currencyDisplay(allSales.productRevenue)}}</v-col>
        <v-col cols="2" class="text-right">{{utils.currencyDisplay(allSales.totalRevenue)}}</v-col>
      </v-row>
      <v-row>
        <v-col cols="4">Last 24 Hours</v-col>
        <v-col cols="2" class="text-right">{{twentyFourHourSales.orders}}</v-col>
        <v-col cols="2" class="text-right">{{twentyFourHourSales.products}}</v-col>
        <v-col cols="2" class="text-right">{{utils.currencyDisplay(twentyFourHourSales.productRevenue)}}</v-col>
        <v-col cols="2" class="text-right">{{utils.currencyDisplay(twentyFourHourSales.totalRevenue)}}</v-col>
      </v-row>
      <v-row>
        <v-col cols="4">Last 7 Days</v-col>
        <v-col cols="2" class="text-right">{{weekSales.orders}}</v-col>
        <v-col cols="2" class="text-right">{{weekSales.products}}</v-col>
        <v-col cols="2" class="text-right">{{utils.currencyDisplay(weekSales.productRevenue)}}</v-col>
        <v-col cols="2" class="text-right">{{utils.currencyDisplay(weekSales.totalRevenue)}}</v-col>
      </v-row>
      <v-row>
        <v-col cols="4">Last 30 Days</v-col>
        <v-col cols="2" class="text-right">{{monthSales.orders}}</v-col>
        <v-col cols="2" class="text-right">{{monthSales.products}}</v-col>
        <v-col cols="2" class="text-right">{{utils.currencyDisplay(monthSales.productRevenue)}}</v-col>
        <v-col cols="2" class="text-right">{{utils.currencyDisplay(monthSales.totalRevenue)}}</v-col>
      </v-row>
    </base-material-card>

  </v-container>
</template>
  
<script>
  import Api from '@/services/Api.js'; 
  import Utils from '@/services/Utils.js';
  import Store from '@/services/Store.js';

  export default {

    components: {
      BaseMaterialCard: () => import('@/components/base/MaterialCard'),
    },  
    
    computed: {
      utils: function () {
        return Utils;
      }
    },

    data () {
      return {
        currentBoxOfficeId: Store.boxOffice.id,
        boxOffice: {},
        user: {},
        allSales: {},
        twentyFourHourSales: {},
        weekSales: {},
        monthSales: {},
      }
    },

    created() {
      this.boxOffice.id = this.$route.params.id;
      this.load();
    },
    
    methods: {
      
      async load() {
        this.boxOffice = await Api.post(this, "BoxOffice", "read", this.boxOffice);
        Store.store(this.boxOffice);
        this.readSalesReport();
      },

      async readSalesReport() {
        this.allSales.type = "boxOffice";
        this.allSales.id  = this.boxOffice.id;
        this.allSales = await Api.post(this, "BoxOffice", "salesSummary", this.allSales)
        let oneDay = 24 * 60 * 60 * 1000;
        this.twentyFourHourSales.type = "boxOffice";
        this.twentyFourHourSales.id  = this.boxOffice.id;
        this.twentyFourHourSales.startDate = new Date().getTime() - oneDay;
        this.twentyFourHourSales = await Api.post(this, "BoxOffice", "salesSummary", this.twentyFourHourSales);
        this.weekSales.id  = this.boxOffice.id;
        this.weekSales.type = "boxOffice";
        this.weekSales.startDate = new Date().getTime() - oneDay * 7;
        this.weekSales = await Api.post(this, "BoxOffice", "salesSummary", this.weekSales);
        this.monthSales.id = this.boxOffice.id;
        this.monthSales.type = "boxOffice";
        this.monthSales.startDate = new Date().getTime() - oneDay * 30;
        this.monthSales = await Api.post(this, "BoxOffice", "salesSummary", this.monthSales);
      },
    }
  }

</script>
